// Generated by Framer (77e91d3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bmuOoE02l", "q_8XSt1WU"];

const serializationHash = "framer-9f8vk"

const variantClassNames = {bmuOoE02l: "framer-v-jkvgny", q_8XSt1WU: "framer-v-pmqx75"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Close: "q_8XSt1WU", Menu: "bmuOoE02l"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "bmuOoE02l", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bmuOoE02l", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16vb438 = activeVariantCallback(async (...args) => {
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-9f8vk", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-jkvgny", className)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"bmuOoE02l"} onTap={onTap16vb438} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({q_8XSt1WU: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1tuzjot"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"slSNNZBSh"} style={{backgroundColor: "var(--token-9a9eaa73-ca55-45c9-bd88-5c84438ffdbf, rgb(33, 33, 33))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{q_8XSt1WU: {rotate: -45}}}/><motion.div className={"framer-1unmi97"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"lyXq6MMlY"} style={{backgroundColor: "var(--token-9a9eaa73-ca55-45c9-bd88-5c84438ffdbf, rgb(33, 33, 33))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{q_8XSt1WU: {rotate: 45}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-9f8vk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9f8vk .framer-r1axdl { display: block; }", ".framer-9f8vk .framer-jkvgny { cursor: pointer; height: 44px; overflow: hidden; position: relative; width: 44px; }", ".framer-9f8vk .framer-1tuzjot { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(62.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-9f8vk .framer-1unmi97 { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(37.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-9f8vk.framer-v-pmqx75 .framer-jkvgny { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 44px); }", ".framer-9f8vk.framer-v-pmqx75 .framer-1tuzjot, .framer-9f8vk.framer-v-pmqx75 .framer-1unmi97 { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"q_8XSt1WU":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 */
const FramerSSCjppqnm: React.ComponentType<Props> = withCSS(Component, css, "framer-9f8vk") as typeof Component;
export default FramerSSCjppqnm;

FramerSSCjppqnm.displayName = "Menu Icon";

FramerSSCjppqnm.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerSSCjppqnm, {variant: {options: ["bmuOoE02l", "q_8XSt1WU"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerSSCjppqnm, [])